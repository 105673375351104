import { uuid } from "vue-uuid";
import { AxiosResponse } from "axios";
import ApiService from "@/services";
import CacheService from "@/services/CacheService";
import { QuoteAdminPartStatuses } from "@/enums/QuoteAdminPartStatuses";
import { UserTypes } from "@/enums/UserTypes";
import { CostingSortingSectionEnum } from "@/enums/costing/costingSortingSectionEnum";
import { costingSummaryRoleEnum } from "@/enums/costing/costingSummaryRoleEnum";
import { costingTableNameEnum } from "@/enums/costing/costingTableNameEnum";
import { CostingInterface } from "@/types/api/CostingInterface";
import { QuoteFiltersInterface } from "@/types/api/QuoteFiltersInterface";
import RfqListPart from "@/types/request-for-quote/RfqListPart";
import { ShippingChangeInterface } from "@/types/api/ShippingChangeInterface";
import QuoteEngineerAdjustmentInterface from "@/types/summary/QuoteEngineerAdjustmentInterface";
import { RfqPartParamsInterface } from "@/types/api/RfqPartParamsInterface";
import { PartCostingRawMaterialPatchPayload } from "@/types/costing/PartCostingRawMaterialPatchPayload";
import { SetMinAdjustmentGapPayload } from "@/types/api/SetMinAdjustmentGapPayload";
import { SetAdjustmentPerQuantityPayload } from "@/types/api/SetAdjustmentPerQuantityPayload";

export default class QuoteService {
  static createRequestForQuote(
    names: string[],
    contactUser: { customerId: string; userId: string } | undefined
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq", { names, contactUser });
  }

  static checkForRejectedFiles(payload: {
    rfqId: string;
    names: string[];
    contactUser: { customerId: string; userId: string };
    createParts: boolean;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq", payload);
  }

  static updateRequestForQuote(
    id: string,
    names: string[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq", { rfqId: id, names });
  }

  static submitRequestForQuote(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}/send-request`);
  }

  static requestExportOfQuoteFiles(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/export", {
      params: { rfqId },
      baseURL: process.env.VUE_APP_UPLOAD_API_URL,
    });
  }

  static changeQuoteCurrency(
    id: string,
    currencyId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { currencyId });
  }

  static getQuoteList(params: QuoteFiltersInterface): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq", { params });
  }

  static downloadQuoteReport(
    params: QuoteFiltersInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/report", { params, responseType: "blob" });
  }

  static deleteFileFromRfq(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq/file/${id}`);
  }

  static addRfqList(payload: {
    rfqId: string;
    parts: RfqListPart[];
    contactUser: { customerId: string; userId: string } | undefined;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq/list", payload);
  }

  static createRfqPartByFile(payload: {
    rfqId: string;
    parts: {
      customerPNRev: string;
      modelId: string;
      drawingId?: string;
      quantities?: number[];
      remarks?: string[];
    }[];
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq-part", payload);
  }

  static getRfqPartCounts(userId?: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/part-counts", { params: { userId } });
  }

  static updatePart(
    id: string,
    payload: {
      partId: string;
      status: QuoteAdminPartStatuses;
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, payload);
  }

  static updateRfqPartModel(
    id: string,
    modelId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, { modelId });
  }

  static updateRfqPartDrawing(
    id: string,
    drawingId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, { drawingId });
  }

  static updatePartNumber(payload: {
    id: string;
    name: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${payload.id}`, {
      customerPNRev: payload.name,
    });
  }

  static updatePartQuantities(
    id: string,
    quantities: number[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      quantities,
    });
  }

  static updatePartRemarks(
    id: string,
    remarks: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      remarks,
    });
  }

  static updatePartStatus(id: string, status: number): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      status,
    });
  }

  static changeRfqPartReviewedStatus(
    rfqPartId: string,
    reviewedStatus: boolean
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${rfqPartId}`, {
      reviewed: reviewedStatus,
    });
  }

  static updatePartRejectionReason(
    id: string,
    rejectionReason: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      rejectionReason,
    });
  }

  static removePart(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq-part/${id}`);
  }

  static getRfqParts(params: RfqPartParamsInterface): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part", {
      params,
    });
  }

  static getPartProductionSpeedOptions(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/options/production-speed/${partId}`);
  }
  static updatePartProductionSpeed(
    partId: string,
    optionId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${partId}`, {
      productionSpeed: optionId,
    });
  }

  static getPartQualityControlOptions(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/options/quality-control/${partId}`);
  }
  static updatePartQualityControl(
    partId: string,
    optionId: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${partId}`, {
      qualityControl: optionId,
    });
  }
  static getQualityControlTemplate(type: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/quality-control-templates", {
      params: { type },
    });
  }

  static deleteRfqPart(partId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq-part/${partId}`);
  }

  static updateRfqName(id: string, name: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { name });
  }

  static getQuote(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/${id}`);
  }
  static getQuoteAdditionalFiles(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/additional-files/${id}`);
  }

  static updateRfqOption(
    rfqId: string,
    optionType: {
      productionSpeed?: string;
      qualityControl?: string[];
      shipping?: string;
      replaceQualityControl?: boolean;
      partProductionLocations?: Location[];
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/options/${rfqId}`, optionType);
  }

  static getWorkDays(): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/options/work-days");
  }

  static getPartProductionOptions(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/part-production/${rfqId}`);
  }

  static getQualityControlOptions(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/quality-control/${rfqId}`);
  }

  static getShippingOptions(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/options/shipping/${rfqId}`);
  }
  static getShippingOptionsBySalesRegion(params: {
    salesRegionId: string;
    rfqId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/options/shipping", { params });
  }
  static updateShippingOption(params: {
    optionsId: string;
    rfqId: string;
    countryId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch("/v1/rfq/options/shipping", params);
  }

  static getShippingAddress(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq/shipping-address/${rfqId}`);
  }
  static changeShippingAddress(payload: {
    id: string;
    shippingAddress: ShippingChangeInterface;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v1/rfq/shipping-address/${payload.id}`,
      payload.shippingAddress
    );
  }

  static getShippingAddressFromTheLastQuote(
    customerId: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/address/shipping", { params: { customerId } });
  }

  static downloadExcelFile(
    id: string,
    costingVersion = 1
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v${costingVersion}/rfq/excel/download/${id}`, {
      responseType: "arraybuffer",
    });
  }

  static downloadPdfFile(
    rfqId: string,
    costingVersion = 1
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v${costingVersion}/rfq/export/pdf`, {
      params: { rfqId },
      responseType: "blob",
    });
  }

  static uploadCostingFile(
    id: string,
    formData: FormData
  ): Promise<AxiosResponse> {
    return ApiService.post(`/v1/rfq-part/costing/upload/${id}`, formData);
  }

  static downloadCostingFile(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/costing/download", {
      params: {
        rfqId,
        type: "excel",
      },
      responseType: "arraybuffer",
    });
  }

  static downloadQuoteFiles(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/drawings/export", {
      params: {
        rfqId,
      },
      responseType: "arraybuffer",
    });
  }

  static getCostingPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/costing/${rfqPartId}`);
  }

  static updateQuotePart(
    partId: string,
    part: CostingInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/${partId}`, part);
  }

  static verifyPart(params: {
    customerPN: string;
    customerRev?: string;
    rfqPartId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/costing/verify", {
      params,
    });
  }
  static getFiles(params: {
    rfqPartId: string;
    partId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/costing/verification-files/", {
      params,
    });
  }

  static getCostingSummary(
    rfqPartId: string,
    manufacturedRegion: string
  ): Promise<AxiosResponse> {
    return ApiService.get(
      `/v1/rfq-part/summary/${rfqPartId}?region=${manufacturedRegion}`
    );
  }

  static getTechnicalFeedback(params: {
    rfqPartId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-feedback", { params });
  }

  static removeTechnicalFeedback(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq-feedback/message/${id}`);
  }

  static addTechnicalFeedback(feedback: {
    feedbackId: string;
    messages: string[];
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq-feedback/message", {
      feedbackId: feedback.feedbackId,
      messages: feedback.messages,
    });
  }

  static addTechnicalFeedbackDrawing(
    payload: FormData
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/rfq-feedback/upload", payload);
  }

  static removeTechnicalFeedbackDrawing(payload: {
    id: string;
    drawingId: string;
  }): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v1/rfq-feedback/drawings/${payload.id}/${payload.drawingId}`
    );
  }

  static updatePartActiveQuantity(
    id: string,
    index: number
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq-part/${id}`, {
      enabledQuantity: index,
    });
  }

  static changeQuoteStatus(id: string, status: number): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${id}`, { status });
  }

  static downloadContractReviewReport(
    rfqId: string,
    costingVersion = 1
  ): Promise<AxiosResponse> {
    return ApiService.get(
      `/v${costingVersion}/rfq/export/contract-review-report`,
      {
        params: { rfqId },
        responseType: "arraybuffer",
      }
    );
  }

  static downloadBidReport(
    rfqId: string,
    costingVersion = 1
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v${costingVersion}/rfq/export/bid-report`, {
      params: { rfqId },
      responseType: "arraybuffer",
    });
  }

  static getCheckoutFile(
    rfqId: string,
    costingVersion = 1
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v${costingVersion}/rfq/export/checkout/excel`, {
      params: { rfqId },
      responseType: "arraybuffer",
    });
  }

  static getPurchaseOrderTemplate(
    rfqId: string,
    costingVersion = 1
  ): Promise<AxiosResponse> {
    return ApiService.get(
      `/v${costingVersion}/rfq/export/checkout/po-template`,
      {
        params: { rfqId },
        responseType: "arraybuffer",
      }
    );
  }

  static getCompanyInfoTemplate(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/export/checkout/company-info", {
      params: { rfqId },
      responseType: "arraybuffer",
    });
  }

  static setQuotePurchaseOrder(
    rfqId: string,
    purchaseOrderFileId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${rfqId}`, { purchaseOrderFileId });
  }

  static sendTechnicalFeedbackNotification(id: string): Promise<AxiosResponse> {
    return ApiService.post(`/v1/rfq/${id}/send-technical-feedback-email`);
  }

  static createQuoteRevision(id: string): Promise<AxiosResponse> {
    return ApiService.post(`/v1/rfq/revision/${id}`);
  }

  static deleteQuoteRevision(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq/revision/${id}`);
  }

  static getQuoteEngineeringAdjustmentCalculationPreview(
    id: string,
    payload: QuoteEngineerAdjustmentInterface,
    region: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v1/rfq-part/summary/quote-engineer-adjustment/${id}`,
      {
        ...payload,
        region,
      }
    );
  }

  static saveQuoteEngineeringAdjustment(
    id: string,
    payload: QuoteEngineerAdjustmentInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v1/rfq-part/summary/quote-engineer-adjustment/${id}`,
      {
        ...payload,
      }
    );
  }

  static deleteRfq(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/rfq/${id}`);
  }

  static getRfqPartById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/rfq-part/${id}`);
  }
  static getRfqPartByIdV2(
    id: string,
    select: string[]
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${id}`, { params: { select } });
  }
  static getRfqPartInfo(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${id}/part-info`);
  }
  static getPartInfo(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/${id}`);
  }
  static getPartFiles(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/documents/part-info/${id}`);
  }
  static updatePartInfo(
    partId: string,
    part: Partial<CostingInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/${partId}`, part);
  }
  static getPartInfoByPNRev(params: {
    skip: number;
    limit: number;
    customerPN?: string;
    customerRev?: string;
    number?: string;
    rev?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/parts", { params });
  }

  static getPartFilters(
    params: RfqPartParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/filters", { params });
  }

  static getPartDocuments(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/documents/${id}`);
  }
  static deletePartDocument(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/parts/documents/${id}`);
  }
  static addPartDocument(params: {
    partId: string;
    userTypes: UserTypes[];
    fileId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v1/parts/documents", params);
  }
  static deletePartDocumentAttachment(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${id}`, { fileId: null });
  }
  static updatePartDocumentLabel(params: {
    id: string;
    label: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${params.id}`, {
      label: params.label,
    });
  }
  static updatePartDocumentUserTypes(params: {
    id: string;
    userTypes: UserTypes[];
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${params.id}`, {
      userTypes: params.userTypes,
    });
  }
  static updatePartDocumentFile(params: {
    id: string;
    fileId: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/parts/documents/${params.id}`, {
      fileId: params.fileId,
    });
  }

  static getPartPreviews(partIds: string[]): Promise<AxiosResponse> {
    return ApiService.get("/v1/parts/documents/part-previews", {
      params: {
        partIds,
      },
    });
  }

  static getDataForFilter(): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq/for-filters");
  }

  static getPartsToReviewCount(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get("/v1/rfq-part/count-to-review", {
      params: { rfqId },
    });
  }

  // V2

  static getQuoteCostingVersion(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq/${id}/costing-version`);
  }

  static getQuoteV2(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq/${id}`);
  }

  static getQuoteV2Summary(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq/${id}/summary`);
  }

  static updateQuoteV2Summary(id: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq/${id}/summary`);
  }

  static createQuoteRevisionV2(id: string): Promise<AxiosResponse> {
    return ApiService.post(`/v2/rfq/${id}/new-revision`);
  }

  static createReorderedQuote(id: string): Promise<AxiosResponse> {
    return ApiService.post(`/v2/rfq/${id}/re-order`);
  }

  static getPartProductionOptionsV2(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq/${rfqId}/options/parts-production`);
  }

  static updatePartProductionOptionsV2(
    rfqId: string,
    payload: {
      productionSpeed?: string;
      partProductionLocations: Location[];
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/rfq/${rfqId}/options/parts-production`,
      payload
    );
  }

  static getQualityControlOptionsV2(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq/${rfqId}/options/quality-control`);
  }

  static updateQualityControlOptionsV2(
    rfqId: string,
    payload: {
      qualityControl?: string[];
      replaceQualityControl?: boolean;
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/rfq/${rfqId}/options/quality-control`,
      payload
    );
  }

  static getRfqPartsV2(params: RfqPartParamsInterface): Promise<AxiosResponse> {
    return ApiService.get("/v2/rfq-parts/with-relations", {
      params,
    });
  }

  static getRfqPartFeedbackStatusesByIds(params: {
    rfqPartsIds: string[];
    limit: number;
    skip: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/rfq-parts/feedbacks-statuses-by-rfq-part-ids", {
      params,
    });
  }

  static getRfqPartAttachmentsByIds(params: {
    rfqPartsIds: string[];
    limit: number;
    skip: number;
  }): Promise<AxiosResponse> {
    return CacheService.get("/v2/rfq-parts/attachments", {
      params,
    });
  }

  static getMfrRegionForRfqPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${rfqPartId}/mfr-region`);
  }

  static getRfqPartFeedbackStatuses(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${rfqPartId}/feedbacks-statuses`);
  }

  static geCostingMarkups(
    id: string,
    params: {
      mfrRegionId: string;
      rfqPartId: string;
      currencyId: string;
    }
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/costings/${id}/markups/v2`, { params });
  }

  static confirmRfqPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}/confirm`);
  }

  static reopenRfqPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}/reopen`);
  }

  static declineRfqPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}/reject`);
  }

  static getPartProductionSpeedOptionsV2(
    partId: string
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${partId}/options/production-speed`);
  }

  static updatePartProductionSpeedV2(
    partId: string,
    enabledOptionId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/rfq-parts/${partId}/options/production-speed`,
      {
        enabledOptionId,
      }
    );
  }

  static getPartQualityControlOptionsV2(
    partId: string
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${partId}/options/quality-control`);
  }
  static updatePartQualityControlV2(
    partId: string,
    enabledOptionIds: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${partId}/options/quality-control`, {
      enabledOptionIds,
    });
  }

  static getPartInfoV2(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}`);
  }

  static updatePartInfoV2(
    partId: string,
    part: Partial<CostingInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/${partId}`, part);
  }

  static updateCustomerPNRevV2(
    partId: string,
    part: Partial<CostingInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/${partId}/customer-pn-rev`, part);
  }

  static getPartInfoByPNRevV2(params: {
    skip: number;
    limit: number;
    customerPN?: string;
    customerRev?: string;
    number?: string;
    rev?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts", { params });
  }

  static getRfqPartInfoV2(
    rfqPartId: string,
    select?: string[]
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${rfqPartId}`, {
      params: { select },
    });
  }

  static restoreRfqPart(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}/restore-defaults`, {
      rfqPartId,
    });
  }

  static getPartCostingVersions(rfqPartId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/rfq-parts/${rfqPartId}/get-costings-versions`);
  }

  static addPartCostingVersion(
    partId: string,
    measurementUnitId: string,
    rfqPartId: string
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costings/", {
      partId,
      measurementUnitId,
      rfqPartId,
    });
  }

  static switchPartCostingVersion(
    rfqPartId: string,
    costingId: string,
    active: boolean
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}`, {
      costingId,
      active,
    });
  }

  static changeRfqPartActive(
    rfqPartId: string,
    costingId: string,
    active: boolean
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}`, {
      costingId,
      active,
    });
  }

  static updatePartCostingItem(
    costingId: string,
    payload: {
      confirmed?: boolean;
      status?: string;
      complexityCode?: number;
      explanation?: string;
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${costingId}`, payload);
  }

  static updatePartCostingQuantity(
    rfqPartId: string,
    payload: {
      enabledQuantityIdx: number;
      quantities: number[];
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costings/quantity?rfqPartId=${rfqPartId}`,
      payload
    );
  }

  static verifyPartV2(params: {
    customerPN: string;
    customerRev?: string;
    rfqPartId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/rfq-parts/verify", {
      params,
    });
  }

  static getPartCosting(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}/costing`);
  }

  static getCostingSectionItemOrder(
    costingId: string,
    section: CostingSortingSectionEnum
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/costings/${costingId}/sorting`, {
      params: { section },
    });
  }

  static updateCostingSectionItemOrder(
    payload: { id: string; position: number }[]
  ): Promise<AxiosResponse> {
    return ApiService.patch("/v2/sorting/sorting", { sorting: payload });
  }

  static getPartCostingKeywords(params: {
    tableName?: costingTableNameEnum;
    targetId?: string;
    keyword?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v2/keywords`, {
      params,
    });
  }

  static getPartCostingRawMaterial(
    costingId: string,
    mfrRegionId: string
  ): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/costing-raw-materials`, {
      params: { mfrRegionId, costingId },
    });
  }

  static createPartCostingRawMaterial(
    payload: PartCostingRawMaterialPatchPayload
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costing-raw-materials/", payload);
  }

  static createInitPartCostingRawMaterial(payload: {
    requiredMaterial: string;
    selectedMaterialId: string;
    mfrRegionId?: string;
    costingId?: string;
  }): Promise<AxiosResponse> {
    const traceId = uuid.v4();
    return ApiService.post(
      "/v2/parts/costing-raw-materials/init-template",
      payload,
      {
        headers: {
          "Trace-Id": traceId,
        },
      }
    );
  }

  static changePartCostingRawMaterial(
    operationId: string,
    payload: Omit<PartCostingRawMaterialPatchPayload, "mfrRegionId">
  ): Promise<AxiosResponse> {
    const traceId = uuid.v4();
    return ApiService.patch(`/v2/parts/costing-raw-materials`, payload, {
      params: { operationId },
      headers: {
        "Trace-Id": traceId,
      },
    });
  }

  static getPartCostingSurfaceProcessList(params: {
    costingId: string;
    limit: number;
    skip: number;
    mfrRegionId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-surface-processes", { params });
  }

  static recalculateSurfaceProcessList(params: {
    costingId: string;
    limit: number;
    skip: number;
    mfrRegionId: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(
      "/v2/parts/costing-surface-processes/recalculate-surface-processes",
      {},
      { params }
    );
  }

  static addPartCostingSurfaceTreatment(payload: {
    required: string;
    selectedId: string;
    mfrRegionId: string;
    costingId: string;
    costingMarkId?: string;
    min?: number;
    max?: number;
  }): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-surface-processes/surface-treatment",
      payload
    );
  }

  static addPartCostingSurfaceFinish(payload: {
    required: string;
    selectedId: string;
    mfrRegionId: string;
    costingId: string;
    costingMarkId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-surface-processes/surface-finish",
      payload
    );
  }

  static deletePartCostingSurfaceProcess(
    processId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/parts/costing-surface-processes/${processId}`
    );
  }

  static changeSurfaceProcesses(
    costingId: string,
    surfaceProcesses: string[]
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${costingId}`, {
      surfaceProcesses,
    });
  }

  static getPartCostingHeatTreatmentList(params: {
    costingId: string;
    limit: number;
    skip: number;
    mfrRegionId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-heat-treatment-processes", {
      params,
    });
  }

  static addPartCostingHeatTreatment(payload: {
    costingId: string;
    required: string;
    selectedId: string;
    mfrRegionId: string;
    costingMarkId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-heat-treatment-processes",
      payload
    );
  }

  static deletePartCostingHeatTreatment(
    treatmentId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/parts/costing-heat-treatment-processes/${treatmentId}`
    );
  }

  static getPartRelatedQuotes(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/${partId}/related-quotes`);
  }

  static changeQuoteCostingVersion(
    rfqId: string,
    version: number
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/rfq/${rfqId}/costing-version`, { version });
  }

  static connectPartToRfqPart(
    rfqPartId: string,
    partId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-parts/${rfqPartId}/connect-part`, {
      partId,
    });
  }

  // buy item
  static getPartCostingBuyItemList(params: {
    costingId: string;
    limit: number;
    skip: number;
    mfrRegionId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-buy-item-processes", {
      params,
    });
  }

  static addPartCostingBuyItem(payload: {
    costingId: string;
    required: string;
    selectedId: string;
    mfrRegionId: string;
    quantity: number;
    costingMarkId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costing-buy-item-processes", payload);
  }

  static deletePartCostingBuyItem(itemId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/parts/costing-buy-item-processes/${itemId}`);
  }

  // QC
  static updateCostingQC(
    id: string,
    payload: Record<string, number>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${id}/qc`, payload);
  }

  // Classification
  static updateCostingClassification(
    id: string,
    payload: Record<string, string>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${id}/classification`, payload);
  }

  // part complexity
  static updatePartComplexity = (costingId: string, complexityCode: number) => {
    return ApiService.patch(`/v2/parts/costings/${costingId}/complexity-code`, {
      complexityCode,
    });
  };

  //machining-direction
  static getPartCostingMachiningDirections(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.get(
      `/v2/parts/costing-machining-directions/${costingId}`
    );
  }

  static updatePartCostingMachiningDirections(
    costingId: string,
    payload: { modelShapeIds: string[]; directions: string[] }
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costing-machining-directions/${costingId}`,
      payload
    );
  }

  // confirmation
  static getPartCostingConfirmations(params: {
    costingIds: string[];
    limit: number;
    skip: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-confirmations", {
      params,
    });
  }

  static updateCostingConfirmations = (
    confirmationId: string,
    confirmed: boolean
  ) => {
    return ApiService.patch(
      `/v2/parts/costing-confirmations/${confirmationId}`,
      {
        confirmed,
      }
    );
  };

  //Cost and Price summary
  static getPartCostingSummaryBreakDown(params: {
    costingId: string;
    mfrRegionId: string;
    currencyId: string;
    rfqPartId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-summaries/break-down", {
      params,
    });
  }
  static getPartCostingSummary(params: {
    costingId: string;
    mfrRegionId: string;
    role: costingSummaryRoleEnum;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-summaries", {
      params,
    });
  }
  static getPartCostings(costingId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/costings/${costingId}`);
  }
  static getCostingAdjustmentMetadata(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-summaries/metadata", {
      params: { costingId },
    });
  }
  static changeAdjustmentExplanationCost(
    costingId: string,
    explanationCost: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${costingId}`, {
      explanationCost,
    });
  }
  static changeAdjustmentExplanationPrice(
    costingId: string,
    explanationPrice: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${costingId}`, {
      explanationPrice,
    });
  }
  static setMinAdjustmentGap(
    costingId: string,
    payload: SetMinAdjustmentGapPayload
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costings/${costingId}/adjustments`,
      payload
    );
  }
  static getSummaryPreviewWithAdjustmentPerQuantity(
    costingId: string,
    summariesAdjustments: Pick<
      SetAdjustmentPerQuantityPayload,
      "costingSummaryId" | "adjustment"
    >[],
    costingAdjustments: SetMinAdjustmentGapPayload,
    currencyId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `v2/parts/costings/${costingId}/adjustments-preview`,
      {
        summariesAdjustments,
        costingAdjustments,
        currencyId,
      }
    );
  }
  static setAdjustmentPerQuantity(
    costingSummaryId: string,
    payload: {
      adjustment: number;
      flatRateAdjustment: number;
      totalAdjustment: number;
    }
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costing-summaries/${costingSummaryId}`,
      payload
    );
  }

  static changeCostingCurrency(
    costingId: string,
    currencyId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costings/${costingId}`, {
      currencyId,
    });
  }
  static recalculateCosting(
    id: string,
    rfqPartId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costings/${id}/recalculate-all-regions`,
      { rfqPartId }
    );
  }

  static getSummaryPreview(payloadWithId: {
    id: string;
    payload: {
      mfrRegionId: string;
      rfqPartId: string;
      currencyId: string;
    };
  }): Promise<AxiosResponse> {
    const { id, payload } = payloadWithId;
    return ApiService.patch(`/v2/parts/costings/${id}/summary-preview`, {
      mfrRegionId: payload.mfrRegionId,
      rfqPartId: payload.rfqPartId,
      currencyId: payload.currencyId,
    });
  }
}
