import { AxiosResponse } from "axios";

import ApiService from "@/services/index";

export default class CalendarService {
  static getDeliveryDateV2(params: {
    date: string;
    shippingBusinessDays: number;
    deliveryBusinessDays: number;
    countryId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/calendars/delivery-date/v2", {
      params,
    });
  }
}
